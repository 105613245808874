@font-face {
  font-family: 'T.C.SYSTEM';
  src: url('../fonts/TCSYSTEM.eot');
  src: url('../fonts/TCSYSTEM.eot?#iefix') format('embedded-opentype'),
    url('../fonts/TCSYSTEM.woff2') format('woff2'),
    url('../fonts/TCSYSTEM.woff') format('woff'),
    url('../fonts/TCSYSTEM.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@import url('https://fonts.googleapis.com/css?family=Chakra+Petch');
